import React from "react";
import Charts from "../../components/Charts/Charts";

const ChartsPage = ({ selectedJobId }) => {
  return (
    <div>
      <Charts selectedJobId={selectedJobId} />
    </div>
  );
}

export default ChartsPage;
