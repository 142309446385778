import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { parse, getMonth, getISOWeek } from "date-fns";
import { useJobs } from "../../hooks/useJobs/useJobs";
import Button from "../Button/Button";
import HeatmapChart from "./HeatmapChart";
import SpeakersChart from "./SpeakersChart";
import Header from "../Header/Header";
import FilterSelect from "../FilterSelect/FilterSelect";
import MultiSelect from "../MultiSelect/MultiSelect";
import StackedChart from "./StackedChart";
import LogoutButton from "../LogoutButton/Logoutbutton";

const Charts = ({ selectedJobId }) => {
  const { getJobs } = useJobs();

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [genderStats, setGenderStats] = useState({ male: 0, female: 0 });
  const [generalSelectedProgram, setGeneralSelectedProgram] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("day");
  const [selectedDates, setSelectedDates] = useState([]);
  const [chartSeries, setChartSeries] = useState([0, 0]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();
        setJobs(response);
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
      }
    };

    fetchJobs();
  }, [getJobs]);

  useEffect(() => {
    if (generalSelectedProgram) {
      const filtered = jobs.filter((job) =>
        job.titol.includes(generalSelectedProgram)
      );
      setFilteredJobs(filtered);
      setSelectedProgram("");
    } else {
      setFilteredJobs([]);
      setSelectedProgram("");
    }
  }, [generalSelectedProgram, jobs]);

  useEffect(() => {
    const calculateGenderStats = async () => {
      if (!selectedDates.length || !selectedFilter) {
        setGenderStats({ male: 0, female: 0 });
        setChartSeries([0, 0]);
        return;
      }

      let maleCount = 0;
      let femaleCount = 0;

      const jobsInSelectedDates = filteredJobs.filter((job) => {
        const dateStr = job.date;
        if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return false;

        const date = parse(dateStr, "dd/MM/yyyy", new Date());
        if (selectedFilter === "week") {
          const weekNumber = getISOWeek(date);
          return selectedDates.some((date) => date.value === weekNumber);
        } else if (selectedFilter === "month") {
          const monthNumber = getMonth(date) + 1;
          return selectedDates.some((date) => date.value === monthNumber);
        } else if (selectedFilter === "day") {
          return job.titol === selectedDates[0];
        }
        return false;
      });

      jobsInSelectedDates.forEach((job) => {
        job.results[0].forEach((result) => {
          if (result.gender === "MALE") {
            maleCount++;
          } else if (result.gender === "FEMALE") {
            femaleCount++;
          }
        });
      });

      const totalCount = maleCount + femaleCount;

      if (totalCount > 0) {
        const malePercentage = (maleCount / totalCount) * 100;
        const femalePercentage = (femaleCount / totalCount) * 100;

        setGenderStats({
          male: malePercentage.toFixed(2),
          female: femalePercentage.toFixed(2),
        });

        setChartSeries([
          parseFloat(malePercentage.toFixed(2)),
          parseFloat(femalePercentage.toFixed(2)),
        ]);
      } else {
        setGenderStats({ male: 0, female: 0 });
        setChartSeries([0, 0]);
      }
    };

    calculateGenderStats();
  }, [selectedFilter, selectedDates, filteredJobs]);

  const formatJobTitle = (titol) => {
    const parts = titol.split(" - ");
    return parts[0].trim();
  };

  const getUniqueWeeksAndMonths = () => {
    let uniqueWeeks = new Set();
    let uniqueMonths = new Set();

    filteredJobs.forEach((job) => {
      const dateStr = job.date;
      if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return;
      const date = parse(dateStr, "dd/MM/yyyy", new Date());

      if (selectedFilter === "week") {
        uniqueWeeks.add(getISOWeek(date));
      } else if (selectedFilter === "month") {
        uniqueMonths.add(getMonth(date) + 1);
      }
    });

    return selectedFilter === "week"
      ? Array.from(uniqueWeeks)
      : Array.from(uniqueMonths);
  };

  const uniqueDates = getUniqueWeeksAndMonths().map((dateValue) => ({
    value: dateValue,
    label:
      selectedFilter === "week" ? `Setmana ${dateValue}` : `Mes ${dateValue}`,
  }));

  const programOptions = Array.from(
    new Set(jobs.map((job) => formatJobTitle(job.titol)))
  ).map((titulo) => ({ value: titulo, label: titulo }));

  const filteredProgramOptions = filteredJobs.map((job) => ({
    value: job.titol,
    label: job.titol,
  }));

  const chartOptions = {
    chart: {
      type: "pie",
      width: "100%",
    },
    labels: ["HOMES", "DONES"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const sortedFilteredJobs = filteredJobs.sort((a, b) => {
    const dateA = parse(a.date, "dd/MM/yyyy", new Date());
    const dateB = parse(b.date, "dd/MM/yyyy", new Date());

    return dateA - dateB;
  });

  const handleGeneralProgramChange = (program) => {
    setGeneralSelectedProgram(program);
    setSelectedProgram("");
    setSelectedFilter("day");
    setSelectedDates([]);
  };

  const handleProgramChange = (program) => {
    setSelectedProgram(program);
    if (selectedFilter === "day") {
      setSelectedDates([program]);
    }
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === "day" && selectedProgram) {
      setSelectedDates([selectedProgram]);
    } else {
      setSelectedDates([]);
    }
  };

  return (
    <>
      <LogoutButton />
      <Header />
      <div
        id="chart"
        className="w-full max-w-screen-lg mx-auto overflow-x-auto"
      >
        <main className="p-4 min-h-screen w-full">
          <div className="flex justify-end mb-4">
            <Button to="/">
              <img src="/img/icon_arrow.png" alt="Torna" />
            </Button>
          </div>
          <div>
            <div className="m-12">
              <span className="text-black text-base font-bold">
                Pots seleccionar primer el programa que vols visualitzar i
                després el dia a través d’aquests filtres. Tingues en compte que
                hi ha 2 gràfics que hauràs de seleccionar individualment si vols
                veure els resultats amb més detall.
              </span>
              <FilterSelect
                options={programOptions}
                selectedValue={generalSelectedProgram}
                onChange={(option) => handleGeneralProgramChange(option.value)}
              />
              {generalSelectedProgram && (
                <div>
                  <FilterSelect
                    options={filteredProgramOptions}
                    selectedValue={selectedProgram}
                    onChange={(option) => handleProgramChange(option.value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="flex justify-center">
              <SpeakersChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
            <h1 className="text-xs text-black">
              Percentatges de sexe per dia, setmana o mes
            </h1>
            <span className="italic text-xs text-black">
              Selecciona entre "dia", "setmana" o "mes" per obtindre resultats
              del programa seleccionat anteriorment.
            </span>
          </div>
          <div className="flex flex-row mb-4">
            <FilterSelect
              options={[
                { value: "day", label: "Dia" },
                { value: "week", label: "Setmana" },
                { value: "month", label: "Mes" },
              ]}
              selectedValue={selectedFilter}
              onChange={(option) => handleFilterChange(option.value)}
            />
            {selectedFilter && selectedFilter !== "day" && (
              <div className="mb-4">
                <MultiSelect
                  options={uniqueDates}
                  selectedValues={selectedDates}
                  onChange={setSelectedDates}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col justify-center space-y-6 md:space-y-8 lg:space-y-10">
            <div className="flex justify-center">
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="pie"
                width="100%"
              />
            </div>
            <div className="flex justify-center">
              <StackedChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
            <div className="flex justify-center">
              <HeatmapChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Charts;
