import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import React, { useEffect, useState, useCallback } from "react";
import { useJobs } from "../../hooks/useJobs/useJobs";
import DeleteModal from "../DeleteModal/DeleteModal";

const JobsList = ({ onJobClick, onJobSelect }) => {
  library.add(faTrashCan);
  const { getJobs, getJobsPagination, deleteJob } = useJobs();
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [increasedTotalJobs, setIncreasedTotalJobs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [titleFilter, setTitleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(false);
  const [temaFilter, setTemaFilter] = useState("");

  const jobsPerPage = 15;

  const fetchJobs = useCallback(
    async (page = 1) => {
      try {
        if (titleFilter || statusFilter || temaFilter) {
          const response = await getJobs();
          const allJobs = response || [];

          const filtered = allJobs.filter((job) => {
            const titleMatch = job.titol
              .toLowerCase()
              .includes(titleFilter.toLowerCase());
            const statusMatch = statusFilter ? job.status === "waiting" : true;
            const temaMatch = temaFilter
              ? job.tematica.text.toLowerCase() === temaFilter.toLowerCase()
              : true;

            return titleMatch && statusMatch && temaMatch;
          });

          setFilteredJobs(filtered);

          const totalFilteredJobs = filtered.length;
          setTotalJobs(totalFilteredJobs);

          const totalFilteredPages = Math.ceil(totalFilteredJobs / jobsPerPage);
          setTotalPages(totalFilteredPages);

          setIncreasedTotalJobs(totalFilteredJobs);
        } else {
          const { jobs, totalPages, total } = await getJobsPagination(
            page,
            jobsPerPage
          );

          setJobs(jobs);

          setTotalPages(totalPages);

          setTotalJobs(total);

          const jobsDisplayed = (page - 1) * jobsPerPage + jobs.length;
          setIncreasedTotalJobs(jobsDisplayed);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
        setJobs([]);

        setTotalPages(0);

        setTotalJobs(0);

        setIncreasedTotalJobs(0);
      }
    },
    [
      getJobs,
      getJobsPagination,
      titleFilter,
      statusFilter,
      temaFilter,
      jobsPerPage,
    ]
  );

  useEffect(() => {
    const debounceFetch = setTimeout(() => {
      fetchJobs(currentPage);
    }, 300);

    return () => clearTimeout(debounceFetch);
  }, [fetchJobs, currentPage, titleFilter, statusFilter, temaFilter]);

  const truncateDescription = (description) => {
    if (!description) {
      return "Sense dades en la descripció";
    }
    return description.split(" ").slice(0, 10).join(" ");
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDelete = async (jobId) => {
    try {
      const success = await deleteJob(jobId);
      if (success) {
        if (titleFilter || statusFilter || temaFilter) {
          fetchJobs(currentPage);
        } else {
          const newTotalJobs = totalJobs - 1;
          const newTotalPages = Math.ceil(newTotalJobs / jobsPerPage);

          setTotalJobs(newTotalJobs);
          setTotalPages(newTotalPages);

          if (currentPage > newTotalPages) {
            setCurrentPage(newTotalPages);
          }

          fetchJobs(currentPage);
        }
      }
    } catch (error) {
      console.error("Error deleting job:", error.message);
    }
  };

  const openModal = (jobId) => {
    setJobToDelete(jobId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setJobToDelete(null);
    setModalIsOpen(false);
  };

  const confirmDelete = () => {
    if (jobToDelete) {
      handleDelete(jobToDelete);
    }
  };

  const shouldShowPaginationButtons = jobs.length >= jobsPerPage;

  return (
    <main className="flex flex-col items-center p-4">
      <div className="overflow-x-auto w-full">
        <div className="flex flex-col sm:flex-row mb-4 w-full">
          <input
            type="text"
            placeholder="Filtra per títol o data"
            className="mb-2 sm:mb-0 sm:mr-2 px-4 py-2 rounded border border-gray-300 bg-transparent text-black"
            value={titleFilter}
            onChange={(e) => {
              setTitleFilter(e.target.value);
              setCurrentPage(1);
            }}
          />
          <select
            className="px-4 py-2 rounded border border-gray-300 bg-transparent text-black"
            value={temaFilter}
            onChange={(e) => {
              setTemaFilter(e.target.value);
              setCurrentPage(1);
            }}
          >
            <option value="">Tots els temes</option>
            <option value="ficció">Ficció</option>
            <option value="entreteniment">Entreteniment</option>
            <option value="actualitat">Actualitat</option>
          </select>
          <button
            className={`px-4 py-2 rounded mb-2 sm:mb-0 sm:ml-2 ${
              statusFilter ? "bg-red-500 text-white" : "bg-gray-200 text-black"
            }`}
            onClick={() => {
              setStatusFilter(!statusFilter);
              setCurrentPage(1);
            }}
          >
            {statusFilter ? "Mostrar processed" : "Mostrar waiting"}
          </button>
        </div>

        <div className="flex justify-between mt-4 w-full mb-4">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 disabled:bg-gray-300"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span className="text-black">
            Pàgina {currentPage} de {totalPages}
          </span>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Següent
          </button>
        </div>

        <div className="flex justify-center mb-4">
          <span className="text-black text-xs">
            Jobs totals: {increasedTotalJobs} / {totalJobs}
          </span>
        </div>

        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Títol
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Descripció
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              >
                Estat
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Tema
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Acció
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {(titleFilter || statusFilter || temaFilter ? filteredJobs : jobs)
              .length > 0 ? (
              (titleFilter || statusFilter || temaFilter
                ? filteredJobs
                : jobs
              ).map((job) => (
                <tr
                  key={`${job._id}-${job.titol}`}
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    onJobClick(job.id);
                    onJobSelect(job.id);
                  }}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {job.titol}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {truncateDescription(job.descripcio)}...
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {job.status}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {job.tematica.text}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal(job.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                >
                  No s'han trobat treballs
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {shouldShowPaginationButtons && (
        <div className="flex justify- mt-4">
          <span className="text-black text-xs">
            Jobs totals: {increasedTotalJobs} / {totalJobs}
          </span>
        </div>
      )}
      <div className="flex justify-between mt-4 w-full mt-2 mb-2">
        <button
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 disabled:bg-gray-300"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        {shouldShowPaginationButtons && (
          <>
            <span className="text-black">
              Pàgina {currentPage} de {totalPages}
            </span>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Següent
            </button>
          </>
        )}
      </div>

      <DeleteModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onConfirm={confirmDelete}
      />
    </main>
  );
};

export default JobsList;
