import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
    library.add(faRightFromBracket);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("isAuthenticated");
        navigate("/");
    };

    return (
        <button
            onClick={handleLogout}
            className="absolute top-4 right-4 bg-transparent border-none p-0 cursor-pointer flex items-center justify-center"
        >
            <FontAwesomeIcon
                icon={faRightFromBracket}
                className="text-black text-xl transform rotate-180"
            />
        </button>
    );
};

export default LogoutButton;
