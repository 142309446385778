import './App.css';
/* import Header from "./components/Header/Header" */
import AppRouter from "./routers/routes";

function App() {
  return (
    <>
      {/* <Header />  */}
      <AppRouter />
    </>
    
  );
}

export default App;
