import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import JobsPage from "../pages/JobsPage/JobsPage";
import ChartsPage from "../pages/ChartsPage/ChartsPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";

const AppRoutes = () => {
    const [selectedJobId, setSelectedJobId] = useState(null);
    const navigate = useNavigate();

    const handleJobClick = () => {
        navigate('/charts'); 
    };

    const handleJobSelect = (jobId) => {
        setSelectedJobId(jobId);
    };

    return (
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
                path="/jobs"
                element={
                    <PrivateRoute>
                        <JobsPage onJobClick={handleJobClick} onJobSelect={handleJobSelect} />
                    </PrivateRoute>
                }
            />
            <Route
                path="/charts"
                element={
                    <PrivateRoute>
                        <ChartsPage selectedJobId={selectedJobId} />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

const AppRouter = () => (
    <Router>
        <AppRoutes />
    </Router>
);

export default AppRouter;
