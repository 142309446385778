import React from "react";
import JobsList from "../../components/JobsList/JobsList"
import Header from "../../components/Header/Header";
import LogoutButton from "../../components/LogoutButton/Logoutbutton";

const JobsPage = ({ onJobClick, onJobSelect }) => {
  
  return (
    <div>
      <LogoutButton />
      <Header />
      <JobsList onJobClick={onJobClick} onJobSelect={onJobSelect}/>
    </div>
)};

export default JobsPage;
 