const Header = () => {
  return (
    <header className="flex flex-row justify-center items-center p-2 flex-wrap">
      <img src="/img/3cat_logo.png" alt="Logo 3cat" className="w-24 h-24 md:w-32 md:h-32" />
      <h1 className="text-customRed text-3xl md:text-5xl mt-2 md:mt-0">StatMedia</h1>
    </header>
  );
};

export default Header;
 