import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const testPassword = "ugiat2statmedia";

    useEffect(() => {
        const isAuthenticated = localStorage.getItem("isAuthenticated");
        if (isAuthenticated === "true") {
            navigate("/jobs"); 
        }
    }, [navigate]);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password === testPassword) {
            localStorage.setItem("isAuthenticated", "true");
            navigate("/jobs");
        } else {
            console.log("Contraseña incorrecta");
        }
    };

    const handleEnter = (event) => {
        if (event.key === "Enter"){
            event.preventDefault();
            handleSubmit(event)
        }
    }

    return (
        <div className="flex items-center justify-center items-center h-screen">
            <div className="bg-white max-w-md shadow-xl rounded px-4 pt-6 pb-8 mb-4 flex flex-col items-center">
                <div className="flex flex-col items-center justify-center mb-4">
                    <img src="/img/3cat_logo.png" alt="Logo 3cat" className="w-24 h-24 md:w-32 md:h-32"/>
                    <input
                        type="password"
                        id="password"
                        placeholder="Introdueix contrasenya"
                        value={password}
                        onChange={handlePasswordChange}
                        onKeyDown={handleEnter}
                        className="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-4 bg-transparent text-center"
                    />
                </div>
                <div className="flex items-center justify-center w-full">
                    <button
                        id="login"
                        type="submit"
                        onClick={handleSubmit}
                        
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Accedir
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
