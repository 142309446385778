import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const DeleteModal = ({ isOpen, onRequestClose, onConfirm }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Confirmación de Eliminación"
    className="fixed inset-0 flex items-center justify-center z-50"
    overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75"
  >
    <div className="bg-white p-6 max-w-sm w-full h-[230px] rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4 text-black">Confirmació de l'eliminació</h2>
      <p className="mb-6 text-black">Estàs segur que vols eliminar aquest treball?</p>
      <div className="flex justify-end space-x-4">
        <button
          className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
          onClick={onRequestClose}
        >
          Cancel·lar
        </button>
        <button
          className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
          onClick={() => {
            onConfirm();
            onRequestClose();
          }}
        >
          Confirmar
        </button>
      </div>
    </div>
  </Modal>
);

export default DeleteModal;
