import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { useJobs } from "../../hooks/useJobs/useJobs";
import { debounce } from "lodash";
import SpeakersList from "../SpeakersList/SpeakersList";

const SpeakersChart = ({
  selectedJobId,
  generalSelectedProgram,
  selectedProgram,
}) => {
  const { getJobs, getJobById, transformData } = useJobs();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(selectedJobId);
  const [selectedGender, setSelectedGender] = useState("general");
  const [chartKey, setChartKey] = useState(0);
  const chartDataRef = useRef([]);
  const removedBarsRef = useRef([]);
  const removedBarsSetRef = useRef(new Set());
  const originalChartDataRef = useRef([]);
  const [pieData, setPieData] = useState([]);

  const optionsRef = useRef({
    series: [],
    chart: {
      height: 500,
      type: "bar",
      events: {
        dataPointSelection: debounce((event, chartContext, config) => {
          handleBarClick(config);
        }, 300),
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
        barHeight: "70%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: [],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
      },
    },
    title: {
      text: "Presència Speaker",
      floating: false,
      offsetY: 0,
      align: "center",
      style: {
        color: "#444",
      },
    },
  });

  const pieOptionsRef = useRef({
    chart: {
      type: "pie",
      width: 380,
    },
    labels: [],
    title: {
      text: "Distribució de Presència",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return parseFloat(val.toFixed(2)) + "%";
        },
      },
    },
  });

  const fetchData = useCallback(async () => {
    if (!selectedJob) return;
    try {
      const job = await getJobById(selectedJob);
      const transformedData = transformData(job);
      const speakerNames = Object.keys(transformedData);

      const dataWithColors = speakerNames.map((speaker, index) => {
        const { gender, presencePercentage } = transformedData[speaker];
        const genderLabel = gender.charAt(0).toUpperCase();
        const color = gender.toLowerCase() === "male" ? "#008ffb" : "#00e396";
        return {
          name: `S${speaker} (${genderLabel})`,
          originalValue: presencePercentage,
          data: presencePercentage,
          gender: gender.toLowerCase(),
          color: color,
          index,
        };
      });

      const filteredData = dataWithColors.filter(
        (speaker) =>
          selectedGender === "general" ||
          speaker.gender === selectedGender.toLowerCase()
      );

      const recalculatedData = recalculatePercentages(filteredData);

      originalChartDataRef.current = recalculatedData;
      chartDataRef.current = recalculatedData;

      const seriesData = recalculatedData.map(({ name, data, color }) => ({
        x: name,
        y: data,
        fillColor: color,
      }));

      optionsRef.current = {
        ...optionsRef.current,
        series: [
          {
            name: "Presence",
            data: seriesData,
          },
        ],
        xaxis: {
          ...optionsRef.current.xaxis,
          categories: seriesData.map(({ x }) => x),
        },
      };

      setPieData(recalculatedData.map((item) => item.data));
      pieOptionsRef.current = {
        ...pieOptionsRef.current,
        labels: recalculatedData.map(({ name }) => name),
      };

      removedBarsRef.current = [];
      removedBarsSetRef.current = new Set();
      setChartKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error fetching or transforming data:", error);
    }
  }, [selectedJob, selectedGender, getJobById, transformData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobsData = await getJobs();
        setJobs(jobsData);
        if (jobsData.length > 0 && !selectedJobId) {
          setSelectedJob(jobsData[0].id);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, [getJobs, selectedJobId]);

  useEffect(() => {
    if (selectedProgram) {
      const filtered = jobs.filter((job) => job.titol === selectedProgram);
      if (filtered.length > 0) {
        setSelectedJob(filtered[0].id);
      } else {
        setSelectedJob(null);
      }
    }
  }, [selectedProgram, jobs]);

  const handleChangeJob = useCallback((e) => {
    setSelectedJob(e.target.value);
  }, []);

  const handleChangeGender = useCallback((event) => {
    setSelectedGender(event.target.value);
  }, []);

  const recalculatePercentages = useCallback((data) => {
    const totalPresence = data.reduce(
      (sum, item) => sum + item.originalValue,
      0
    );
    return data.map((item) => ({
      ...item,
      data: (item.originalValue / totalPresence) * 100,
    }));
  }, []);

  const handleBarClick = useCallback(
    (config) => {
      const { dataPointIndex, w } = config;

      if (
        !w ||
        !w.config ||
        !w.config.series ||
        !w.config.series[0] ||
        !w.config.series[0].data
      ) {
        console.error("Data is not available for the click event.");
        return;
      }

      const clickedData = w.config.series[0].data[dataPointIndex];
      if (!clickedData) {
        console.error("Clicked data is not available.");
        return;
      }

      const updatedData = chartDataRef.current.filter(
        (item) => item.name.trim() !== clickedData.x.trim()
      );

      if (updatedData.length === 0) {
        console.warn("No data remaining after filtering.");
        return;
      }

      const recalculatedData = recalculatePercentages(updatedData);

      const updatedSeries = recalculatedData.map(({ name, data, color }) => ({
        x: name,
        y: data,
        fillColor: color,
      }));

      chartDataRef.current = recalculatedData;
      optionsRef.current = {
        ...optionsRef.current,
        series: [
          {
            name: "Presence",
            data: updatedSeries,
          },
        ],
        xaxis: {
          ...optionsRef.current.xaxis,
          categories: recalculatedData.map(({ name }) => name),
        },
      };

      setPieData(recalculatedData.map((item) => item.data));
      pieOptionsRef.current = {
        ...pieOptionsRef.current,
        labels: recalculatedData.map(({ name }) => name),
      };

      removedBarsRef.current = [...removedBarsRef.current, clickedData.x];
      removedBarsSetRef.current = new Set(removedBarsRef.current);
      setChartKey((prevKey) => prevKey + 1);
    },
    [recalculatePercentages]
  );

  const handleRestoreBar = useCallback(
    (barName) => {
      const dataToRestore = originalChartDataRef.current.find(
        (item) => item.name.trim() === barName.trim()
      );

      if (!dataToRestore) {
        console.error("No data to restore found.");
        return;
      }

      const restoredData = [
        ...chartDataRef.current.filter(
          (item) => !removedBarsSetRef.current.has(item.name)
        ),
        dataToRestore,
      ];
      const recalculatedData = recalculatePercentages(restoredData);

      const orderedData = recalculatedData.sort((a, b) => a.index - b.index);

      const updatedSeries = orderedData.map(({ name, data, color }) => ({
        x: name,
        y: data,
        fillColor: color,
      }));

      chartDataRef.current = orderedData;
      optionsRef.current = {
        ...optionsRef.current,
        series: [
          {
            name: "Presence",
            data: updatedSeries,
          },
        ],
        xaxis: {
          ...optionsRef.current.xaxis,
          categories: orderedData.map(({ name }) => name),
        },
      };

      setPieData(orderedData.map((item) => item.data));
      pieOptionsRef.current = {
        ...pieOptionsRef.current,
        labels: orderedData.map(({ name }) => name),
      };

      removedBarsRef.current = removedBarsRef.current.filter(
        (bar) => bar !== barName
      );
      removedBarsSetRef.current = new Set(removedBarsRef.current);
      setChartKey((prevKey) => prevKey + 1);
    },
    [recalculatePercentages]
  );

  return (
    <div id="chart" className="w-full max-w-screen-lg mx-auto text-black">
      <div className="mb-4">
        <h1 className="text-xs text-black">Presència Speaker</h1>
        <p className="italic text-xs text-black">
          Pots interactuar amb el gràfic fent click a una barra per eliminar-la
          i obtindre nous resultats.
        </p>
      </div>
      {jobs.length > 0 ? (
        <div>
          <select
            className="text-black bg-transparent border-2 m-2"
            onChange={handleChangeJob}
            value={selectedJob || " "}
          >
            {jobs.map((job) => (
              <option key={job.id} value={job.id}>
                {job.titol}
              </option>
            ))}
          </select>
          <select
            className="text-black bg-transparent border-2"
            onChange={handleChangeGender}
            value={selectedGender}
          >
            <option value="general">General</option>
            <option value="male">Homes</option>
            <option value="female">Dones</option>
          </select>
        </div>
      ) : (
        "Loading jobs..."
      )}
      <div className="overflow-x-auto">
        <div style={{ width: `${chartDataRef.current.length * 60}px` }}>
          <ReactApexChart
            key={chartKey}
            options={optionsRef.current}
            series={optionsRef.current.series}
            type="bar"
            height={optionsRef.current.chart.height}
          />
        </div>
      </div>
      {removedBarsRef.current.length > 0 && (
        <div className="mt-4 p-4 bg-yellow-200 border border-yellow-400 text-yellow-800">
          <p className="text-black">Les barres que has eliminat son:</p>
          <ul>
            {removedBarsRef.current.map((bar, index) => (
              <li
                key={index}
                onClick={() => handleRestoreBar(bar)}
                className="cursor-pointer underline"
              >
                <strong className="text-black">{bar}</strong>
              </li>
            ))}
          </ul>
          <p className="text-xs italic text-black">
            Pots restaurar les barres fent click de nou
          </p>
        </div>
      )}
      <div className="flex flex-row gap-4">
        <div className="flex-grow">
          <SpeakersList selectedJobId={selectedJob} />
        </div>
        <div className="flex-grow">
          <ReactApexChart
            options={pieOptionsRef.current}
            series={pieData}
            type="pie"
            width={400}
          />
        </div>
      </div>
    </div>
  );
};

export default SpeakersChart;
