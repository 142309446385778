import React, { useEffect, useState, useRef } from "react";
import { useJobs } from "../../hooks/useJobs/useJobs";

const SpeakersList = ({ selectedJobId }) => {
  const { getSpeakersByJobId } = useJobs();
  const [speakers, setSpeakers] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    const fetchSpeakers = async () => {
      try {
        const speakersData = await getSpeakersByJobId(selectedJobId);
        setSpeakers(speakersData || []);
      } catch (error) {
        console.error("Error fetching speakers:", error.message);
        setSpeakers([]);
      }
    };

    if (selectedJobId) {
      fetchSpeakers();
    }
  }, [selectedJobId, getSpeakersByJobId]);

  const playAudio = async (speakerWithGender) => {
    try {
      const speakerName = speakerWithGender.split(" (")[0];
      const response = await fetch(
        `https://statmedia.ugiat.com/api/audio/${selectedJobId}/${speakerName}`,
        {
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      setAudioUrl(url);
      setTimeout(() => {
        if (audioRef.current) {
          audioRef.current.play().catch((error) => {
            console.error("Error during playback:", error.message);
          });
        }
      }, 0);
    } catch (error) {
      console.error("Error playing audio:", error.message);
    }
  };

  const groupSpeakers = (arr, size) => {
    arr.sort((a, b) => {
      let numA = parseInt(a.match(/\d+/)[0], 10);
      let numB = parseInt(b.match(/\d+/)[0], 10);
      return numA - numB;
    });

    let result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }

    return result;
  };

  const speakerGroups = groupSpeakers(speakers, 4);

  if (!speakers || speakers.length === 0) {
    return <div>No speakers available</div>;
  }

  const handleAudioEvent = (event) => {
    if (event.type === "error") {
      console.error("Error event on audio element", event);
      const errorDetails = event.target.error;
      if (errorDetails) {
        console.error("Audio Error Details: ", errorDetails);
      }
    }
  };

  return (
    <div className="m-4">
      <h2>Fent clic al speaker que vulguis podràs escoltar la seva veu.</h2>
      {speakerGroups.map((group, groupIndex) => (
        <ul key={groupIndex} className="flex flex-row">
          {group.map((speaker, index) => (
            <li key={index} className="m-2 text-xs">
              <button
                className="text-black bg-white"
                onDoubleClick={() => playAudio(speaker)}
              >
                {speaker}
              </button>
            </li>
          ))}
        </ul>
      ))}
      {audioUrl && (
        <div key={audioUrl} className="mt-4 flex justify-center">
          <audio
            ref={audioRef}
            controls
            onPlay={handleAudioEvent}
            onPause={handleAudioEvent}
            onEnded={handleAudioEvent}
            onError={handleAudioEvent}
          >
            <source src={audioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default SpeakersList;
