import { useNavigate } from "react-router-dom"; 

const Button = ({ children, to, onButtonClick }) => {
  const navigate = useNavigate(); 

  const handleClick = () => {
    if (to) {
      navigate(to);
    } else if (onButtonClick) {
      onButtonClick();
    }
  };

  return (
    <button className="text-black border-none bg-transparent" onClick={handleClick}>
      {children}
    </button>
  );
};

export default Button;
