import React from "react";
import Select from "react-select";

const FilterSelect = ({ options, selectedValue, onChange }) => (
  <div className="mb-4">
    <Select
      className="text-black bg-transparent border-2 m-2"
      value={options.find((option) => option.value === selectedValue)}
      onChange={onChange}
      options={options}
      placeholder="Selecciona una opció"
    />
  </div>
);

export default FilterSelect;
