import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { parse, getISOWeek, getMonth, format } from "date-fns";
import { useJobs } from "../../hooks/useJobs/useJobs";
import FilterSelect from "../FilterSelect/FilterSelect";
import MultiSelect from "../MultiSelect/MultiSelect";

const StackedChart = ({ generalSelectedProgram, selectedProgram }) => {
  const { getJobs } = useJobs();

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("day"); // Inicializado en "day"
  const [selectedDates, setSelectedDates] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uniqueWeeksAndMonths, setUniqueWeeksAndMonths] = useState([]);
  const [uniqueDays, setUniqueDays] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();
        setJobs(response);
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
      }
    };

    fetchJobs();
  }, [getJobs]);

  useEffect(() => {
    if (generalSelectedProgram) {
      const filtered = jobs.filter((job) =>
        job.titol.includes(generalSelectedProgram)
      );
      setFilteredJobs(filtered);
    } else {
      setFilteredJobs([]);
    }
  }, [generalSelectedProgram, jobs]);

  useEffect(() => {
    const getUniqueWeeksAndMonths = () => {
      let uniqueValues = new Set();

      filteredJobs.forEach((job) => {
        const dateStr = job.date;
        if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return;
        const date = parse(dateStr, "dd/MM/yyyy", new Date());

        if (selectedFilter === "SETMANA" || selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          uniqueValues.add(weekLabel);
        } else if (selectedFilter === "MES" || selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          uniqueValues.add(monthLabel);
        } else if (selectedFilter === "day") {
          const dayLabel = format(date, "dd/MM/yyyy");
          uniqueValues.add(dayLabel);
        }
      });

      return Array.from(uniqueValues).map((value) => ({
        value: value,
        label: value,
      }));
    };

    setUniqueWeeksAndMonths(getUniqueWeeksAndMonths());
    setUniqueDays(getUniqueWeeksAndMonths().filter(value => value.label.match(/^\d{2}\/\d{2}\/\d{4}$/)));
  }, [selectedFilter, filteredJobs]);

  useEffect(() => {
    const calculateGenderStats = async () => {
      if (!selectedFilter) {
        setChartSeries([]);
        setCategories([]);
        return;
      }

      let maleCounts = {};
      let femaleCounts = {};
      let totalCounts = {};

      const jobsInSelectedDates = filteredJobs.filter((job) => {
        const dateStr = job.date;
        if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return false;

        const date = parse(dateStr, "dd/MM/yyyy", new Date());
        if (selectedFilter === "SETMANA") {
          const weekNumber = getISOWeek(date);
          return selectedDates.some(
            (selectedDate) => selectedDate.value === `Setmana ${weekNumber}`
          );
        } else if (selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === weekLabel
          );
        } else if (selectedFilter === "MES") {
          const monthNumber = getMonth(date) + 1;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === `Mes ${monthNumber}`
          );
        } else if (selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === monthLabel
          );
        } else if (selectedFilter === "day") {
          return selectedDates.some(
            (selectedDate) => selectedDate.value === format(date, "dd/MM/yyyy")
          );
        }
        return false;
      });

      jobsInSelectedDates.forEach((job) => {
        const dateStr = job.date;
        const date = parse(dateStr, "dd/MM/yyyy", new Date());

        if (selectedFilter === "SETMANA") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;

          if (!maleCounts[weekLabel]) maleCounts[weekLabel] = 0;
          if (!femaleCounts[weekLabel]) femaleCounts[weekLabel] = 0;
          if (!totalCounts[weekLabel]) totalCounts[weekLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[weekLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[weekLabel]++;
            }
            totalCounts[weekLabel]++;
          });
        } else if (selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          const dayLabel = format(date, "dd/MM/yyyy");

          if (!maleCounts[weekLabel]) maleCounts[weekLabel] = {};
          if (!femaleCounts[weekLabel]) femaleCounts[weekLabel] = {};
          if (!totalCounts[weekLabel]) totalCounts[weekLabel] = {};

          if (!maleCounts[weekLabel][dayLabel])
            maleCounts[weekLabel][dayLabel] = 0;
          if (!femaleCounts[weekLabel][dayLabel])
            femaleCounts[weekLabel][dayLabel] = 0;
          if (!totalCounts[weekLabel][dayLabel])
            totalCounts[weekLabel][dayLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[weekLabel][dayLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[weekLabel][dayLabel]++;
            }
            totalCounts[weekLabel][dayLabel]++;
          });
        } else if (selectedFilter === "MES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;

          if (!maleCounts[monthLabel]) maleCounts[monthLabel] = 0;
          if (!femaleCounts[monthLabel]) femaleCounts[monthLabel] = 0;
          if (!totalCounts[monthLabel]) totalCounts[monthLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[monthLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[monthLabel]++;
            }
            totalCounts[monthLabel]++;
          });
        } else if (selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          const dayLabel = format(date, "dd/MM/yyyy");

          if (!maleCounts[monthLabel]) maleCounts[monthLabel] = {};
          if (!femaleCounts[monthLabel]) femaleCounts[monthLabel] = {};
          if (!totalCounts[monthLabel]) totalCounts[monthLabel] = {};

          if (!maleCounts[monthLabel][dayLabel])
            maleCounts[monthLabel][dayLabel] = 0;
          if (!femaleCounts[monthLabel][dayLabel])
            femaleCounts[monthLabel][dayLabel] = 0;
          if (!totalCounts[monthLabel][dayLabel])
            totalCounts[monthLabel][dayLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[monthLabel][dayLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[monthLabel][dayLabel]++;
            }
            totalCounts[monthLabel][dayLabel]++;
          });
        } else if (selectedFilter === "day") {
          const dayLabel = format(date, "dd/MM/yyyy");

          if (!maleCounts[dayLabel]) maleCounts[dayLabel] = 0;
          if (!femaleCounts[dayLabel]) femaleCounts[dayLabel] = 0;
          if (!totalCounts[dayLabel]) totalCounts[dayLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[dayLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[dayLabel]++;
            }
            totalCounts[dayLabel]++;
          });
        }
      });

      let newCategories = [];
      let maleData = [];
      let femaleData = [];

      if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
        newCategories = Object.keys(maleCounts);
        maleData = newCategories.map((label) =>
          ((maleCounts[label] / totalCounts[label]) * 100).toFixed(2)
        );
        femaleData = newCategories.map((label) =>
          ((femaleCounts[label] / totalCounts[label]) * 100).toFixed(2)
        );
      } else if (
        selectedFilter === "SETMANA - DIES" ||
        selectedFilter === "MES - DIES"
      ) {
        newCategories =
          selectedFilter === "SETMANA - DIES"
            ? Object.keys(maleCounts).flatMap((weekLabel) =>
                Object.keys(maleCounts[weekLabel])
              )
            : Object.keys(maleCounts).flatMap((monthLabel) =>
                Object.keys(maleCounts[monthLabel])
              );

        maleData = newCategories.map((dayLabel) => {
          const parentLabel =
            selectedFilter === "SETMANA - DIES"
              ? `Setmana ${getISOWeek(
                  parse(dayLabel, "dd/MM/yyyy", new Date())
                )}`
              : `Mes ${
                  getMonth(parse(dayLabel, "dd/MM/yyyy", new Date())) + 1
                }`;
          return (
            (maleCounts[parentLabel][dayLabel] /
              totalCounts[parentLabel][dayLabel]) *
            100
          ).toFixed(2);
        });

        femaleData = newCategories.map((dayLabel) => {
          const parentLabel =
            selectedFilter === "SETMANA - DIES"
              ? `Setmana ${getISOWeek(
                  parse(dayLabel, "dd/MM/yyyy", new Date())
                )}`
              : `Mes ${
                  getMonth(parse(dayLabel, "dd/MM/yyyy", new Date())) + 1
                }`;
          return (
            (femaleCounts[parentLabel][dayLabel] /
              totalCounts[parentLabel][dayLabel]) *
            100
          ).toFixed(2);
        });
      } else if (selectedFilter === "day") {
        newCategories = Object.keys(maleCounts);
        maleData = newCategories.map((dayLabel) =>
          ((maleCounts[dayLabel] / totalCounts[dayLabel]) * 100).toFixed(2)
        );
        femaleData = newCategories.map((dayLabel) =>
          ((femaleCounts[dayLabel] / totalCounts[dayLabel]) * 100).toFixed(2)
        );
      }

      setCategories(newCategories);
      setChartSeries([
        {
          name: "HOMES",
          data: maleData,
        },
        {
          name: "DONES",
          data: femaleData,
        },
      ]);
    };

    calculateGenderStats();
  }, [selectedFilter, selectedDates, filteredJobs, selectedProgram]);

  useEffect(() => {
    if (selectedFilter === "day" && selectedProgram) {
      const programDay = filteredJobs.find((job) => job.titol === selectedProgram);
      if (programDay) {
        const programDate = format(parse(programDay.date, "dd/MM/yyyy", new Date()), "dd/MM/yyyy");
        setSelectedDates([{ value: programDate, label: programDate }]);
      }
    }
  }, [selectedFilter, selectedProgram, filteredJobs]);

  const sortedFilteredJobs = filteredJobs.sort((a, b) => {
    const dateA = parse(a.date, "dd/MM/yyyy", new Date());
    const dateB = parse(b.date, "dd/MM/yyyy", new Date());
    return dateA - dateB;
  });

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === "day" && selectedProgram) {
      const programDay = filteredJobs.find((job) => job.titol === selectedProgram);
      if (programDay) {
        const programDate = format(parse(programDay.date, "dd/MM/yyyy", new Date()), "dd/MM/yyyy");
        setSelectedDates([{ value: programDate, label: programDate }]);
      } else {
        setSelectedDates([]);
      }
    } else {
      setSelectedDates([]);
    }
  };

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      height: 500,
      width: "1000",
    },
    xaxis: {
      categories: categories,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
  };

  return (
    <div id="chart" className="w-full max-w-screen-lg mx-auto">
      <div className="mb-4">
        <h1 className="text-xs text-black">
          Resultats acomulats per dia, setmana o mes
        </h1>
      </div>
      <div className="flex flex-row mb-4">
        <FilterSelect
          options={[
            { value: "day", label: "Dia" },
            { value: "SETMANA", label: "Setmana" },
            { value: "SETMANA - DIES", label: "Setmana - Dies" },
            { value: "MES", label: "Mes" },
            { value: "MES - DIES", label: "Mes - Dies" },
          ]}
          selectedValue={selectedFilter}
          onChange={(option) => handleFilterChange(option.value)}
        />
        {selectedFilter && selectedFilter !== "day" && (
          <div className="mb-4">
            <MultiSelect
              options={uniqueWeeksAndMonths}
              selectedValues={selectedDates}
              onChange={(selectedOptions) => {
                const updatedSelectedDates = Array.isArray(selectedOptions)
                  ? selectedOptions
                  : [selectedOptions];
                setSelectedDates(updatedSelectedDates);
              }}
              displayValue={(option) => option.label}
            />
          </div>
        )}
        {selectedFilter === "day" && (
          <div className="mb-4">
            <MultiSelect
              options={uniqueDays}
              selectedValues={selectedDates}
              onChange={(selectedOptions) => {
                const updatedSelectedDates = Array.isArray(selectedOptions)
                  ? selectedOptions
                  : [selectedOptions];
                setSelectedDates(updatedSelectedDates);
              }}
              displayValue={(option) => option.label}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col justify-center space-y-6 md:space-y-8 lg:space-y-10">
        <div className="flex justify-center">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={chartOptions.chart.height}
            width={chartOptions.chart.width}
          />
        </div>
      </div>
    </div>
  );
};

export default StackedChart;
