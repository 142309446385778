import axios from "axios";
import { useCallback } from "react";

export const useJobs = () => {
  const appEndpoint = "/jobs";
  const apiKey = "https://statmedia.ugiat.com/api";

  const getJobs = useCallback(async () => {
    try {
      const response = await axios.get(`${apiKey}${appEndpoint}`, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status !== 200) {
        throw new Error("Jobs not found");
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching jobs:", error.message);
      throw new Error("Error fetching jobs: " + error.message);
    }
  }, [apiKey, appEndpoint]);

  const getJobsPagination = useCallback(
    async (page = 1, perPage = 15) => {
      try {
        const response = await axios.get(`${apiKey}${appEndpoint}`, {
          headers: { "Content-Type": "application/json" },
          params: { page, per_page: perPage },
        });

        if (response.status !== 200) {
          throw new Error("Jobs not found");
        }

        const jobsData = Array.isArray(response.data.jobs)
          ? response.data.jobs
          : [];
        const pagination = response.data.total_pages;
        const totalJobs = response.data.total;

        return { jobs: jobsData, totalPages: pagination, total: totalJobs };
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
        throw new Error("Error fetching jobs: " + error.message);
      }
    },
    [apiKey, appEndpoint]
  );

  const getJobById = useCallback(
    async (jobId) => {
      try {
        const url = `${apiKey}${appEndpoint}/${jobId}`;
        const response = await axios.get(url, {
          headers: { "Content-Type": "application/json" },
        });
        if (response.status !== 200) {
          throw new Error(`Job with ID ${jobId} not found`);
        }

        return response.data;
      } catch (error) {
        console.error(`Error fetching job with ID ${jobId}:`, error.message);
        throw new Error(
          `Error fetching job with ID ${jobId}: ${error.message}`
        );
      }
    },
    [apiKey, appEndpoint]
  );

  const transformData = useCallback((job) => {
    const speakerData = {};

    job.results[0].forEach((interaction) => {
      const { speaker, start, end, gender } = interaction;
      if (!speakerData[speaker]) {
        speakerData[speaker] = {
          gender,
          interactions: 0,
          totalTime: 0,
        };
      }
      speakerData[speaker].interactions += 1;
      speakerData[speaker].totalTime += end - start;
    });

    const totalInteractions = Object.values(speakerData).reduce(
      (acc, curr) => acc + curr.interactions,
      0
    );

    const totalTime = Object.values(speakerData).reduce(
      (acc, curr) => acc + curr.totalTime,
      0
    );

    Object.values(speakerData).forEach((speaker) => {
      speaker.interactionPercentage =
        (speaker.interactions / totalInteractions) * 100;
      speaker.timePercentage = (speaker.totalTime / totalTime) * 100;
      speaker.presencePercentage = speaker.interactionPercentage;
    });

    return speakerData;
  }, []);

  const deleteJob = useCallback(
    async (jobId) => {
      try {
        const response = await axios.delete(
          `${apiKey}${appEndpoint}/${jobId}`,
          {
            headers: { "Content-Type": " application/json" },
          }
        );
        if (response.status === 200) {
          return true;
        } else {
          throw new Error("Error deleting job");
        }
      } catch (error) {
        console.error("Error deleting job: ", error.message);
        throw new Error("Error deleting job: " + error.message);
      }
    },
    [apiKey, appEndpoint]
  );

  const getSpeakersByJobId = useCallback(
    async (jobId) => {
      try {
        const url = `${apiKey}${appEndpoint}/${jobId}`;
        const response = await axios.get(url, {
          headers: { "Content-Type": "application/json" },
        });
        if (response.status !== 200) {
          throw new Error(`Speakers for Job with ID ${jobId} not found`);
        }
  
        const results = response.data.results;
  
        if (results.length > 0) {
          const speakersWithGender = results[0].map((segment) => {
            const gender = segment.gender === 'MALE' ? 'M' : 'F';
            return `${segment.name_speaker} (${gender})`;
          });
          const uniqueSpeakersWithGender = [...new Set(speakersWithGender)];
          return uniqueSpeakersWithGender;
        }
        return [];
      } catch (error) {
        console.error(
          `Error fetching speakers for Job with ID ${jobId}:`,
          error.message
        );
        throw new Error(
          `Error fetching speakers for Job with ID ${jobId}: ${error.message}`
        );
      }
    },
    [apiKey, appEndpoint]
  );
  
  

  return {
    getJobs,
    getJobsPagination,
    getJobById,
    transformData,
    deleteJob,
    getSpeakersByJobId,
  };
};
