import LoginForm from "../../components/LoginForm/LoginForm"

const LoginPage = () => {
    return(
        <div>
            <LoginForm />
        </div>
    )
}

export default LoginPage
