import React from 'react';
import Select from 'react-select';

const MultiSelect = ({ label, options, selectedValues, onChange }) => (
  <div className="mb-4">
    <Select
      isMulti
      options={options}
      value={selectedValues}
      onChange={onChange}
      className="text-black bg-transparent border-2 m-2"
      styles={{
        control: (base) => ({
          ...base,
          border: '2px solid #000',
        }),
      }}
    />
  </div>
);

export default MultiSelect;
